import { CalendarNew, Plant, Star } from '@kvika/audur-icons';
import { AppStates } from 'utils/Constants';
import { ChildSvg, ShieldSvg } from '../../components/Icons';
import { Colors } from '../../styles/Styleguide';
import { TranslatedStrings } from '../Translations';

export enum Route {
  Home = '/home',
  Login = '/',
  CreateAccount = '/create-account',
  IsPEP = '/is-pep',
  PEPUser = '/pep-user',
  Settings = '/settings',
  ExtraSavingsAccountsCreate = '/savings-accounts/create-account',
  TermDepositAccountsCreate = '/term-deposit-accounts/create-account',
  FutureAccountsCreate = '/future-accounts/create-account',
  IndexedSavingsAccountsDefault = '/indexed-accounts',
  IndexedSavingsAccountsCreate = '/indexed-accounts/create-account',
  ConfirmAML = '/confirm-aml',
  OverviewAML = '/overview-aml',
  Transfer = '/transfer',
  ChooseAccount = '/choose-account',
  LegalEntityOnboarding = '/legal-entity-onboarding',
  IdentityConfirmation = '/identity-confirmation',
  ThankYouForConfirming = '/thank-you-for-confirming',
  CreateNewAccount = '/create-new-accounts',
  Transactions = '/transactions/[accountId]',
}

export type NavLinks = {
  Home: Route;
  Login: Route;
  CreateAccount: Route;
  IsPEP: Route;
  PEPUser: Route;
  Settings: Route;
  ExtraSavingsAccounts: {
    Create: Route;
  };
  TermDepositAccounts: {
    Create: Route;
  };
  FutureAccounts: {
    Create: Route;
  };
  IndexedSavingsAccounts: {
    Default: Route;
    Create: Route;
  };
  ConfirmAML: Route;
  OverviewAML: Route;
  Transfer: Route;
  ChooseAccount: Route;
  LegalEntityOnboarding: Route;
  IdentityConfirmation: Route;
  ThankYouForConfirming: Route;
  CreateNewAccount: Route;
  Transactions: Route;
};

export const Links: NavLinks = {
  Home: Route.Home,
  Login: Route.Login,
  CreateAccount: Route.CreateAccount,
  IsPEP: Route.IsPEP,
  PEPUser: Route.PEPUser,
  Settings: Route.Settings,
  ExtraSavingsAccounts: {
    Create: Route.ExtraSavingsAccountsCreate,
  },
  TermDepositAccounts: {
    Create: Route.TermDepositAccountsCreate,
  },
  FutureAccounts: {
    Create: Route.FutureAccountsCreate,
  },
  IndexedSavingsAccounts: {
    Default: Route.IndexedSavingsAccountsDefault,
    Create: Route.IndexedSavingsAccountsCreate,
  },
  ConfirmAML: Route.ConfirmAML,
  OverviewAML: Route.OverviewAML,
  Transfer: Route.Transfer,
  ChooseAccount: Route.ChooseAccount,
  LegalEntityOnboarding: Route.LegalEntityOnboarding,
  IdentityConfirmation: Route.IdentityConfirmation,
  ThankYouForConfirming: Route.ThankYouForConfirming,
  CreateNewAccount: Route.CreateNewAccount,
  Transactions: Route.Transactions,
};

export const nonFetchingDataRoutes: Route[] = [
  Links.Login,
  Links.CreateAccount,
  Links.ConfirmAML,
  Links.OverviewAML,
  Links.LegalEntityOnboarding,
  Links.ChooseAccount,
  Links.IdentityConfirmation,
  Links.ThankYouForConfirming,
];

export const protectedNavLinks = {
  [AppStates.login]: [Links.Login],
  [AppStates.chooseAccount]: [Links.ChooseAccount],
  [AppStates.onboarding]: [
    Links.CreateAccount,
    Links.ConfirmAML,
    Links.OverviewAML,
    Links.LegalEntityOnboarding,
    Links.IdentityConfirmation,
    Links.ThankYouForConfirming,
    Links.IsPEP,
    Links.PEPUser,
  ],
  [AppStates.inApp]: [
    Links.Home,
    Links.Settings,
    Links.ExtraSavingsAccounts.Create,
    Links.TermDepositAccounts.Create,
    Links.FutureAccounts.Create,
    Links.Transfer,
    Links.CreateNewAccount,
    Links.Transactions,
  ],
};

export const nonNavigationPathnames: Route[] = [
  Links.Login,
  Links.ConfirmAML,
  Links.OverviewAML,
  Links.CreateAccount,
  Links.LegalEntityOnboarding,
  Links.ChooseAccount,
  Links.PEPUser,
  Links.IsPEP,
  Links.IdentityConfirmation,
  Links.ThankYouForConfirming,
];

export const widerContentPathnames: Route[] = [Links.CreateNewAccount];

export const AUDUR_LINKS = {
  termsAndConditions: 'https://audur.is/skilmalar/vidskiptaskilmalar',
  frequentlyAskedQuestions: 'https://www.audur.is/algengar-spurningar',
  contactUs: 'https://audur.is/hafa-samband',
};

export enum AccountType {
  savings = 'savings',
  term = 'term',
  indexed = 'indexed',
  future = 'future',
  children = 'children',
}

export const AccountTypeData = {
  [AccountType.savings]: {
    icon: <Star />,
    color: Colors.Green,
    backgroundColor: Colors.GreenOpacity,
    title: TranslatedStrings.is.home.savingsAccounts,
  },
  [AccountType.term]: {
    icon: <CalendarNew />,
    color: Colors.LightOrange,
    backgroundColor: Colors.LightOrangeOpacity,
    title: TranslatedStrings.is.home.termAccounts,
  },
  [AccountType.indexed]: {
    icon: <ShieldSvg />,
    color: Colors.Lila,
    backgroundColor: Colors.LilaOpacity,
    title: TranslatedStrings.is.home.indexedAccounts,
  },
  [AccountType.future]: {
    icon: <Plant />,
    color: Colors.BabyBlue,
    backgroundColor: Colors.BabyBlueOpacity,
    title: TranslatedStrings.is.home.futureAccounts,
  },
  [AccountType.children]: {
    icon: <ChildSvg />,
    color: Colors.Yellow,
    backgroundColor: Colors.YellowOpacity,
    title: TranslatedStrings.is.home.childrenAccounts,
  },
};
export const pathsWithBackButton = [Links.Transactions, Links.Transfer, Links.CreateNewAccount];
