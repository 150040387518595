import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { AppStates } from 'utils/Constants';
import { useAppContext } from '../../store/AppContext';
import { getLocalStorageItem, LocalStorageKeys } from '../browserStorage/LocalStorage';
import { protectedNavLinks, Route } from '../navigation/navigation';
import { useLogin } from './useLogin';

const checkSessionExpiration = () => {
  const userSession = getLocalStorageItem(LocalStorageKeys.AUDUR_USER);
  const accessSession = getLocalStorageItem(LocalStorageKeys.USER_ACCESS);

  // If both user and access sessions are undefined, session is expired
  return userSession === undefined && accessSession === undefined;
};

const useSessionExpirationCheck = () => {
  const { handleLogout } = useLogin();
  const router = useRouter();
  const { dispatch, state } = useAppContext();
  const { isCompanyEntity } = state;

  useEffect(() => {
    const interval = setInterval(() => {
      if (checkSessionExpiration()) {
        if (!protectedNavLinks[AppStates.login].includes(router.pathname as Route)) {
          handleLogout(isCompanyEntity);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch, router, handleLogout, isCompanyEntity]);
};

export default useSessionExpirationCheck;
