import { DisruptionTypeEnum } from '@kvika/api-types';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import DocumentSigningHandler from 'pageComponents/create-account/register/DocumentSigningHandler';
import {
  Links,
  nonFetchingDataRoutes,
  nonNavigationPathnames,
  protectedNavLinks,
  Route,
  widerContentPathnames,
} from 'utils/navigation/navigation';
import { AppStates } from 'utils/Constants';
import { UserAccessSchema } from '@kvika/audur-api-types';
import Navigation from '../containers/Navigation/Navigation';
import CookieHubLoader from '../containers/cookie-consent/CookieHubLoader';
import DisruptionModal from '../containers/modals/DisruptionModal';
import ServerErrorModal from '../containers/modals/ServerErrorModal';
import KvikaDocumentSigningHandler from '../pageComponents/app/KvikaDocumentSigningHandler';
import MaintenanceMode from '../pageComponents/app/MaintenanceMode/MaintenanceMode';
import Page from '../pageComponents/app/Page';
import Footer from '../pageComponents/app/footer/Footer';
import { AppProvider, useAppContext } from '../store/AppContext';
import { ActionType } from '../store/Reducers';
import { circularXX, GlobalStyles } from '../styles/GlobalStyles';
import { getInitialUser, isStaging } from '../utils/Utils';
import useFetchUserData from '../utils/hooks/useFetchUserData';
import useSessionExpirationCheck from '../utils/hooks/useSessionExpirationCheck';

const StyledMainContent = styled.div<{ $widerContent?: boolean; $topSpacing?: boolean }>`
  width: 100%;
  max-width: ${(props) => (props.$widerContent ? '1440px' : '1216px')};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${(props) => (props.$topSpacing ? '40px' : '0')};
  padding-bottom: 112px;
  overflow-x: hidden;

  @media all and (max-width: 45em) {
    padding-bottom: 137px;
  }
  @media all and (max-width: 30em) {
    padding-top: ${(props) => (props.$topSpacing ? '32px' : '0')};
  }
  @media all and (max-width: 25em) {
    padding-bottom: 242px;
  }
`;

const LayoutWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const PrivateRouteCheck = ({ children }: { children: ReactElement }) => {
  const { state, dispatch } = useAppContext();
  const { user, access } = state;
  const [dataFetched, setDataFetched] = useState<string | undefined>(undefined);
  useSessionExpirationCheck();

  const [isRoutingFromLogin, setIsRoutingFromLogin] = useState(false);
  const router = useRouter();
  const { pathname } = router;

  const { serviceStatusMode, audurError } = state;

  const isNotInMaintenanceMode = serviceStatusMode.serviceStatus !== DisruptionTypeEnum.MAINTENANCE;
  const isLegalEntityOnboarding = pathname === Links.LegalEntityOnboarding;
  const showNavigation =
    !nonNavigationPathnames.some((link) => pathname === link) && user && isNotInMaintenanceMode && !isRoutingFromLogin;
  const widerContent = !!(widerContentPathnames.find((link) => pathname === link) && user && isNotInMaintenanceMode);

  const { fetchDataForUser, fetchAllAccess } = useFetchUserData();

  /** Few things to be aware of:
   * 1) The initial render of any page needs to be the same in both the server (SSR)
   *    and the client so that React hydration works properly.
   * 2) Accessing localStorage is only possible in client-side code.
   * 3) useEffect only runs client side.
   * We therefore need to make sure that the every page's initial render has an undefined user so it's the same in client and server.
   * This useEffect runs only once after mounting and sets the user client side to the user in the localStorage.
   * We need the user to be in localStorage so that a user can refresh a page without being logged out.
   */
  useEffect(() => {
    const initialUser = getInitialUser();
    if (initialUser) {
      dispatch({ type: ActionType.UpdateLoginResponse, payload: initialUser });
    }
  }, [dispatch, fetchDataForUser, pathname]);

  useEffect(() => {
    // handleRouteProtection redirects user to correct page depending on access and it fetches revevant data
    const handleRouteProtection = async () => {
      let newAccess: UserAccessSchema[] | undefined = access ? [access] : undefined;
      if (!newAccess) {
        newAccess = await fetchAllAccess();
      }

      const currentAccess = newAccess && newAccess.find((a) => a.isCurrent);

      // if there is no access redirect user to login
      if (!newAccess && !protectedNavLinks[AppStates.login].includes(pathname as Route)) {
        router.push(Links.Login);
        return;
      }
      if (currentAccess) dispatch({ type: ActionType.UpdateAccess, payload: currentAccess });

      // **Is current, is onboarded**: Any public route (e.g., login) redirects to `/home`.
      if (
        currentAccess &&
        currentAccess.isOnboarded &&
        !protectedNavLinks[AppStates.inApp].includes(pathname as Route) &&
        !protectedNavLinks[AppStates.chooseAccount].includes(pathname as Route)
      ) {
        router.push(Links.Home);
        return;
      }
      // **Is current, is not onboarded, is not a company**: Any public route (e.g., `/login`) or logged-in route (e.g., `/home`) redirects to `/create-account`.
      if (
        currentAccess &&
        !currentAccess.isOnboarded &&
        !currentAccess.isCompany &&
        !protectedNavLinks[AppStates.onboarding].includes(pathname as Route) &&
        !protectedNavLinks[AppStates.chooseAccount].includes(pathname as Route)
      ) {
        router.push(Links.CreateAccount);
        return;
      }
      // **Is current, is not onboarded, is a company**: Any public route (e.g., login) or logged-in route (e.g., `/home`) redirects to `/legal-entity-onboarding`.
      if (
        currentAccess &&
        !currentAccess.isOnboarded &&
        currentAccess.isCompany &&
        !protectedNavLinks[AppStates.onboarding].includes(pathname as Route) &&
        !protectedNavLinks[AppStates.chooseAccount].includes(pathname as Route)
      ) {
        router.push(Links.LegalEntityOnboarding);
        return;
      }
      // **No "is current"**: pages need to be onboarding or choose account, otherwise redirect to /choose-account.
      // when onboarding company access looks like [{access of individual}] and no access for that company account, that is why we let onboarding pages
      if (
        newAccess &&
        !currentAccess &&
        !protectedNavLinks[AppStates.chooseAccount].includes(pathname as Route) &&
        !protectedNavLinks[AppStates.onboarding].includes(pathname as Route)
      ) {
        router.push({
          pathname: Links.ChooseAccount,
          query: { user: !newAccess.find((a) => a.isCompany) ? 'individual' : 'company' },
        });
        return;
      }
      if (
        currentAccess &&
        (dataFetched === undefined || dataFetched !== currentAccess.ssn) &&
        !nonFetchingDataRoutes.includes(pathname as Route)
      ) {
        await fetchDataForUser(currentAccess);
        await setDataFetched(currentAccess.ssn);
      }
    };
    handleRouteProtection();
    // eslint-disable-next-line
  }, [pathname, dataFetched]);

  // This is for hiding the navigation bar during the brief time it takes
  // to route from Login to Home if the Home page isn't already cached.
  useEffect(() => {
    const handleStart = (url: string) => {
      if (router.pathname === Links.Login && url === Links.Home) {
        setIsRoutingFromLogin(true);
      }
    };
    const handleComplete = () => {
      setIsRoutingFromLogin(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  return (
    <>
      <LayoutWrapper>
        {showNavigation && <Navigation />}
        <StyledMainContent $widerContent={widerContent} $topSpacing={showNavigation}>
          {isNotInMaintenanceMode ? children : <MaintenanceMode />}
        </StyledMainContent>
        {!isLegalEntityOnboarding && <Footer />}
      </LayoutWrapper>
      <ServerErrorModal
        isModalOpen={audurError.displayErrorToUser}
        handleClose={() =>
          dispatch({ type: ActionType.UpdateApiError, payload: { apiError: undefined, displayErrorToUser: false } })
        }
        serverError={audurError.apiError}
      />
      <DisruptionModal />
      <CookieHubLoader />
    </>
  );
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>Auður</title>
        {isStaging() && <meta name="robots" content="noindex" />}
        <link rel="icon" href="/favicon.ico" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Auður" />
        <meta name="og:title" content="Innskráning" />
        <meta name="og:description" content="Skráðu þig inn í Auði" />
        <meta name="og:image" content="/images/audur-share.png" />
        <meta name="apple-itunes-app" content="app-id=1523911435" />
      </Head>
      <AppProvider>
        <KvikaDocumentSigningHandler>
          <DocumentSigningHandler>
            <main className={`${circularXX.variable}`}>
              <GlobalStyles />
              <PrivateRouteCheck>
                <Page>
                  <Component {...pageProps} />
                </Page>
              </PrivateRouteCheck>
            </main>
          </DocumentSigningHandler>
        </KvikaDocumentSigningHandler>
      </AppProvider>
    </>
  );
};

export default MyApp;
