import {
  AccountSchema,
  ChildSchema,
  GetUsersProfileResponseSchema,
  ProductSchema,
  UserAccessSchema,
} from '@kvika/audur-api-types';
import { getAccountsWithNamedFutureAccounts } from '@kvika/audur-utils';
import { useCallback, useMemo, useState } from 'react';
import { FutureAccountStrings } from 'utils/strings';

import { InterestSummary } from 'api/ApiClient';
import AudurApiError from 'api/ApiError';
import { DebouncedFunc, throttle } from 'lodash';
import AudurWebApiClient from '../../api/AudurWebApiClient';
import { errorHandling, useAppContext } from '../../store/AppContext';
import { ActionType } from '../../store/Reducers';
import { SentryErrors } from '../SentryWrapper';
import { getInitialUser } from '../Utils';

type FetchUserDataState = {
  isLoading: boolean;
  fetchDataForUser: DebouncedFunc<(access?: UserAccessSchema | undefined) => Promise<void>>;
  fetchAllAccess: (apiClient?: AudurWebApiClient) => Promise<UserAccessSchema[] | undefined>;
};

const useFetchUserData = (): FetchUserDataState => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { state, dispatch } = useAppContext();
  const { user } = state;

  const fetchAllAccess = useCallback(
    async (apiClient?: AudurWebApiClient): Promise<UserAccessSchema[] | undefined> => {
      try {
        const client = apiClient ?? new AudurWebApiClient({ dispatch });
        const access = await client.getAudurWebApiClient().getUserAccess();
        return access;
        // dispatch({ type: ActionType.UpdateAccess, payload: currentUserAccess }); // eslint-disable-line
      } catch {
        return undefined;
      }
    },
    [dispatch]
  );

  const fetchAccounts = useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      await apiClient
        .getAudurWebApiClient()
        .getAccounts()
        .then((accountsResponse: Array<AccountSchema>) => {
          // This is done to prevent the logged in child to see its own name as the account name. Instead we provide dummy names
          const payload = user?.isChild
            ? getAccountsWithNamedFutureAccounts(accountsResponse, FutureAccountStrings.FutureAccountShort)
            : accountsResponse;
          dispatch({
            type: ActionType.UpdateAccounts,
            payload,
          });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.GetAccountsError,
          });
        });
    },
    [dispatch, user?.isChild]
  );

  const fetchClosedAndDeletedAccounts = useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getClosedAndDeletedAccounts()
        .then((accountsResponse: Array<AccountSchema>) => {
          dispatch({
            type: ActionType.UpdateClosedAccounts,
            payload: accountsResponse,
          });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.GetClosedAccountsError,
          });
        });
    },
    [dispatch]
  );

  const fetchInterestSummary = useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getInterestSummary()
        .then((interestSummaryResponse: InterestSummary) => {
          dispatch({
            type: ActionType.UpdateInterestSummary,
            payload: interestSummaryResponse,
          });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.NoInterestSummary,
          });
        });
    },
    [dispatch]
  );

  const fetchProducts = useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getProducts()
        .then((productsResponse: Array<ProductSchema>) => {
          dispatch({
            type: ActionType.UpdateProducts,
            payload: productsResponse,
          });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.NoProductsFound,
          });
        });
    },
    [dispatch]
  );

  const fetchUserProfile = useCallback(
    async (apiClient: AudurWebApiClient, setUser?: boolean): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getUserProfile()
        .then((profileResponse: GetUsersProfileResponseSchema) => {
          dispatch({ type: ActionType.UpdateUserProfile, payload: profileResponse });
          dispatch({ type: ActionType.ShowChildrensAccounts, payload: profileResponse.showChildAccounts });
          dispatch({ type: ActionType.ShowChildBecameAdult, payload: profileResponse.showChildBecameAdult });
          if (setUser)
            dispatch({
              type: ActionType.UpdateLoginResponse,
              payload: { ...profileResponse, isNewUser: false, authorizationToken: '' },
            });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.UserProfileInvalid,
          });
        });
    },
    [dispatch]
  );

  const fetchCompanyProfile = useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getCurrentCompany()
        .then((companyResponse) => {
          dispatch({ type: ActionType.UpdateCompany, payload: companyResponse });
          dispatch({
            type: ActionType.UpdateUsersChildren,
            payload: undefined,
          });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.UserProfileInvalid,
          });
        });
    },
    [dispatch]
  );

  const fetchChildren = useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getUsersChildren()
        .then((childrenResponse: Array<ChildSchema>) => {
          dispatch({
            type: ActionType.UpdateUsersChildren,
            payload: childrenResponse,
          });
        })
        .catch((error: AudurApiError) => {
          /* displayErrorToUser is false because even though this call fails we can still see the
             childrens future accounts from the getAccounts call so the site works fine you just can't
             create new future accounts and we handle that in the button */
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: false,
            sentryErrorString: SentryErrors.UsersChildrenError,
          });
        });
    },
    [dispatch]
  );

  const fetchDataForUser = useCallback(
    async (access?: UserAccessSchema): Promise<void> => {
      setIsLoading(true);

      const apiClient = new AudurWebApiClient({ dispatch });

      dispatch({
        type: ActionType.UpdateApiClient,
        payload: apiClient,
      });

      try {
        // TODO: do something about next line
        const user = getInitialUser();
        const pepApprovalPending = user?.pepApprovalPending;
        // If pepApprovalPending is false, it should not matter if the user has a pepDocument or not
        const hasPEPdocument = pepApprovalPending && Boolean(user && 'pepDocument' in user && user.pepDocument);
        let userAccess = access;

        if (!userAccess) {
          const allAccess = await fetchAllAccess(apiClient);
          userAccess = allAccess && allAccess.find((a) => a.isCurrent);
        }

        if (userAccess) {
          dispatch({
            type: ActionType.UpdateAccess,
            payload: userAccess,
          });
        }

        if ((pepApprovalPending || hasPEPdocument) && !userAccess?.isCompany) {
          // ! UI doesn't seem to be behaving correctly when this statement is run
          setIsLoading(false);
          return;
        }

        const fetchAccountsPromise = fetchAccounts(apiClient);
        const fetchInterestSummaryPromise = fetchInterestSummary(apiClient);
        const fetchProductsPromise = fetchProducts(apiClient);

        const promises = [fetchAccountsPromise, fetchInterestSummaryPromise, fetchProductsPromise];
        if (userAccess?.isCompany) {
          promises.push(fetchCompanyProfile(apiClient));
        } else {
          promises.push(fetchClosedAndDeletedAccounts(apiClient));
          promises.push(fetchUserProfile(apiClient, !!user));
          promises.push(fetchChildren(apiClient));
        }
        await Promise.all(promises);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorHandling({
          dispatch,
          error: error as AudurApiError,
          displayErrorToUser: false,
        });
      }
    },
    [
      dispatch,
      setIsLoading,
      fetchAllAccess,
      fetchAccounts,
      fetchChildren,
      fetchClosedAndDeletedAccounts,
      fetchCompanyProfile,
      fetchInterestSummary,
      fetchProducts,
      fetchUserProfile,
    ]
  );

  const throttledFetchDataForUser = useMemo(
    () => throttle(fetchDataForUser, 2000, { leading: true, trailing: false }),
    [fetchDataForUser]
  );
  return {
    isLoading,
    fetchDataForUser: throttledFetchDataForUser,
    fetchAllAccess,
  };
};

export default useFetchUserData;
