import { ApiError as KvikaApiError } from '@kvika/api-client';
import { AnswerReadSchema, QuestionGroupSchema, QuestionSchema, RelevantPersonsWriteSchema } from '@kvika/api-types';
import {
  AccountSchema,
  AddressSchema,
  AddressWriteSchema,
  LoginResponseSchema,
  NoticeSchema,
  ProductSchema,
  ProductType,
  TransactionSchema,
} from '@kvika/audur-api-types';
import { Colors } from '@kvika/audur-theme';
import {
  answerDisablesSurvey,
  ChoiceCaption,
  differenceInDays,
  getAccountOwner,
  getIsChildAccount,
  getMonthsFromTermProperty,
  stringToValidInteger,
  SurveyAnswer,
  TagName,
} from '@kvika/audur-utils';
import AudurApiError from 'api/ApiError';
import { ClipboardEvent, KeyboardEvent } from 'react';
import { GeneralStrings, getTermDepositInfoText, LoginStrings, NewAccountStrings } from 'utils/strings';
import { SegmentPageId } from './AudurAnalytics';
import { getUserFirstSawRecurringAMLAt, getLocalStorageItem, LocalStorageKeys } from './browserStorage/LocalStorage';
import { DAYS_ALLOWED_TO_SKIP_AML, MAX_AMOUNT_LENGTH } from './Constants';
import {
  ChildrensProductTypes,
  ErrorHeadingsIcelandic,
  ExtraSavingsAccounts,
  ProductTypes,
  ServerErrorCode,
  ServerErrorMessage,
  ServerErrorMessageIcelandic,
  Term,
  TransactionErrorMessage,
  TransactionErrorMessageIcelandic,
} from './types/APITypes';
import { ErrorData, FeatureFlag, PrismicData, RegistrationInfo, SelectedAccount } from './types/Types';

// Development

const getTier = (): 'production' | 'staging' => {
  const TIER = process.env.NEXT_PUBLIC_AUDUR_ENV || '';
  if (TIER === 'production') {
    return TIER;
  }
  return 'staging';
};

export const isProduction = (): boolean => getTier() === 'production';
export const isStaging = (): boolean => getTier() === 'staging';

// Sorting
export type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function descendingComparatorRec<T>(a: T, b: T, orderBy: keyof T) {
  return descendingComparator(a, b, orderBy);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparatorRec(a, b, orderBy)
    : (a, b) => -descendingComparatorRec(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const sortTransactionsByCreatedAt = (a: TransactionSchema | NoticeSchema, b: TransactionSchema | NoticeSchema) =>
  a.createdAt > b.createdAt ? -1 : 1;

// General

// eslint-disable-next-line consistent-return
export const copyToClipboard = (text: string, callback?: () => void) => {
  try {
    navigator.clipboard.writeText(text);
    callback && callback();
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn('Copy to clipboard failed.', ex);
    return false;
  }
};

// Prevent tabbing for carousel steps
// eslint-disable-next-line consistent-return
export const ignoreTab = (event: KeyboardEvent) => {
  if (event.key === 'Tab') {
    event.preventDefault();
    return false;
  }
};

export const isUnauthorized = (statusCode?: number) => {
  return statusCode === 401;
};

export const isTransferDisabled = (amount: string, balance?: number): boolean => {
  const amountAsInt = stringToValidInteger(amount);
  if (balance === undefined) {
    return true;
  }
  return amount.length <= 0 || amountAsInt <= 0 || balance - amountAsInt < 0;
};

export const getCreateTermAccountInfo = (products: ProductSchema[]) => {
  const termProducts = products.filter((product) => product.type === 'term_deposit');
  const minInterest = Math.min(...termProducts.map((p) => p.interestRate || Infinity));
  const maxInterest = Math.max(...termProducts.map((p) => p.interestRate || 0));
  const monthsArray = termProducts.map((p) => getMonthsFromTermProperty(p.term ?? Term.Months3));
  return getTermDepositInfoText(minInterest, maxInterest, monthsArray);
};

export const getParsedPasteData = (event: ClipboardEvent<HTMLInputElement>) => {
  const copiedText = event.clipboardData?.getData('text');
  const parsedcopiedText = event.clipboardData?.getData('text').replace(/-/g, '').trim();
  const dashCount = (copiedText.match(/-/g) || []).length;
  const splitTextInDashes = copiedText.split('-');
  const bankValue = splitTextInDashes[0];
  const branchValue = splitTextInDashes[1];
  const accountValue = splitTextInDashes[2];

  return {
    parsedcopiedText,
    dashCount,
    bankValue,
    branchValue,
    accountValue,
  };
};

// Error handling

export const getErrorHeading = (
  errorMessage: ServerErrorMessageIcelandic | TransactionErrorMessageIcelandic | GeneralStrings | LoginStrings
) => {
  switch (errorMessage) {
    default:
      return ErrorHeadingsIcelandic.DEFAULT;
  }
};

export const getErrorData = (error?: AudurApiError | KvikaApiError): ErrorData => {
  const detail = getErrorMessageAsString(error);
  return { detail, type: error?.response?.data.type, code: error?.response?.data.code ?? '' };
};

export const getErrorMessage = (errorData?: ErrorData) => {
  if (errorData?.detail === ServerErrorMessage.AUTH_ERROR) {
    switch (errorData.code) {
      case ServerErrorCode.AUTH_ERROR__AUDKENNI_FAILURE:
        return ServerErrorMessageIcelandic.AUTH_ERROR_USER_CANCELLATION;
      case ServerErrorCode.AUTH_ERROR__YOUNGER_THAN_MINIMUM_REGISTRATION_AGE:
        return LoginStrings.UserToYoungText;
      default: {
        return GeneralStrings.ErrorMessage;
      }
    }
  }
  switch (errorData?.detail) {
    case TransactionErrorMessage.ACCOUNT_NOT_AVAILABLE:
      return TransactionErrorMessageIcelandic.ACCOUNT_NOT_AVAILABLE;
    case TransactionErrorMessage.ACCOUNT_NOT_OWNED:
      return TransactionErrorMessageIcelandic.ACCOUNT_NOT_OWNED;
    case TransactionErrorMessage.NOT_ENOUGH_BALANCE:
      return TransactionErrorMessageIcelandic.NOT_ENOUGH_BALANCE;
    case TransactionErrorMessage.TRANSACTION_ON_CREATION_DAY:
      return TransactionErrorMessageIcelandic.TRANSACTION_ON_CREATION_DAY;
    case TransactionErrorMessage.TERM_DEPOSIT_CREATION_OUTSIDE_HOURS:
      return TransactionErrorMessageIcelandic.TERM_DEPOSIT_CREATION_OUTSIDE_HOURS;
    case ServerErrorMessage.AUTHENTICATION_FAILED:
      return ServerErrorMessageIcelandic.AUTHENTICATION_FAILED;
    case ServerErrorMessage.YOU_ARE_NOT_AUTHORIZED:
      return ServerErrorMessageIcelandic.YOU_ARE_NOT_AUTHORIZED;
    case ServerErrorMessage.POWER_OF_ATTORNEY_ERROR:
      return ServerErrorMessageIcelandic.POWER_OF_ATTORNEY_ERROR;
    case ServerErrorMessage.SOPRA_MIGRATION:
      return ServerErrorMessageIcelandic.SOPRA_MIGRATION;
    case ServerErrorMessage.INVALID_PHONE_NUMBER:
      return ServerErrorMessageIcelandic.INVALID_PHONE_NUMBER;
    case ServerErrorMessage.REGISTRATION_CANT_BE_COMPLETED:
      return ServerErrorMessageIcelandic.REGISTRATION_CANT_BE_COMPLETED;
    case TransactionErrorMessage.RTGS_SYSTEM_CLOSED:
      return TransactionErrorMessageIcelandic.RTGS_SYSTEM_CLOSED;
    case TransactionErrorMessage.DEPOSIT_ACCOUNT_LOCKED:
      return TransactionErrorMessageIcelandic.DEPOSIT_ACCOUNT_LOCKED;
    case TransactionErrorMessage.WITHDRAWAL_LIMIT_OUTSIDE_HOURS_EXCEEDED:
      return TransactionErrorMessageIcelandic.WITHDRAWAL_LIMIT_OUTSIDE_HOURS_EXCEEDED;
    case TransactionErrorMessage.WITHDRAWAL_ACCOUNT_INSUFFICIENT_FUNDS:
      return TransactionErrorMessageIcelandic.WITHDRAWAL_ACCOUNT_INSUFFICIENT_FUNDS;
    default: {
      return GeneralStrings.ErrorMessage;
    }
  }
};

export const getErrorMessageAsString = (error?: AudurApiError | KvikaApiError) => {
  const detail = error?.response?.data.detail;
  if (detail) {
    if (typeof detail === 'string') {
      return detail;
    }
    return detail.length > 0 ? detail[0].msg : '';
  }
  return '';
};

// 401 errors tend to clutter Sentry as they occur "naturally" all the time,
// i.e. when user tokens expire or they cancel Auðkenni during login so lets not log them
export function shouldLogToSentry(error: AudurApiError | KvikaApiError | undefined) {
  if (isUnauthorized(error?.response?.status)) {
    return false;
  }
  return true;
}

// String formatting

/**
 * Formats Icelandic SSN from e.g. 2233445566 to 223344-5566.
 * If input isn't exactly 10 characters like all SSNs should be, it just returns the input string.
 * @param ssn
 */
export const getFormattedSSN = (ssn: string) => {
  // Use non-breaking hyphen for SSN (\u2011)
  return ssn.length === 10 ? `${ssn.slice(0, 6)}\u2011${ssn.slice(6)}` : ssn;
};

// Registration, Prismic and Analytics

export const getDefaultRegistrationInfo = (): RegistrationInfo => {
  return {
    email: '',
    address: {
      address: '',
      city: '',
      postalCode: '',
      country: '',
    },
    externalAccount: {
      bank: '',
      branch: '',
      account: '',
    },
    isPEP: false,
  };
};

export const getRegisterStepName = (currentStep: number, isLivingAbroad: boolean) => {
  const addressInfoName = 'Address Info';
  const stepNames = [
    'Basic User',
    'Employment Status',
    'Is Owner Of Money',
    'Is U S A Tax Payer',
    'Tax Origin',
    'Origin Of Fund',
    'Monthly Turnover',
    'Is Political Risk',
    addressInfoName,
    'Bank Account',
    'Overview',
  ];
  return isLivingAbroad ? stepNames[currentStep] : stepNames.filter((name) => name !== addressInfoName)[currentStep];
};

export const getAddressInfo = (address?: AddressSchema | undefined): AddressWriteSchema => {
  return {
    address: address?.address ?? '',
    city: address?.city ?? '',
    postalCode: address?.postalCode ?? '',
    country: address?.country ?? '',
  };
};

export const showAddressStep = (answers: SurveyAnswer[], isLivingAbroad: boolean) => {
  const isTaxResidenceAbroad = answers.some(
    (answer) => answer.questionTagName === TagName.TaxResidence && answer.choiceCaption !== ChoiceCaption.Iceland
  );
  return isLivingAbroad || isTaxResidenceAbroad;
};

export const getDefaultPrismicData = (): PrismicData => {
  return {
    savingsMarketInterestRates: {
      lowDepositRate: 0.32,
      midDepositRate: 0.35,
      midToHighDepositRate: 0.39,
      highDepositRate: 0.43,
    },
    termDepositMarketInterestRates: {
      threeMonthTermRate: 0.93,
      sixMonthTermRate: 1.11,
      twelveMonthTermRate: 1.3,
    },
    futureMarketInterestRates: {
      averageMarketFutureRate: 0.37,
    },
    inflationRateGoal: 2.5,
    marketInterestRatesCompany: {
      highDepositRate: 7.64,
      lowDepositRate: 7.43,
      midDepositRate: 7.48,
      midToHighDepositRate: 7.54,
    },
  };
};

const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const getPathnameForAnalytics = (pathName: string): string => {
  const pn = toTitleCase(pathName.replace('/', '').replace(/-/g, ' '));
  const title = pn === '' ? SegmentPageId.Login : pn;
  return title;
};

export const getInitialUser = (): LoginResponseSchema | undefined => {
  const user = getLocalStorageItem(LocalStorageKeys.AUDUR_USER);
  if (user) {
    try {
      return JSON.parse(user);
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
};

export const getAccentColor = (accountType?: ProductTypes) => {
  switch (accountType) {
    case ProductType.CompanySavings:
      return Colors.Green;
    case ProductType.Savings:
    case ExtraSavingsAccounts.ExtraSavingsAccounts:
      return Colors.Green;
    case ProductType.Future:
      return Colors.BabyBlue;
    case ProductType.TermDeposit:
      return Colors.LightOrange;
    case ProductType.IndexedSavings:
      return Colors.Red;
    case ChildrensProductTypes.ChildrensSavings:
    case ChildrensProductTypes.ChildrensTermDeposit:
    case ChildrensProductTypes.ChildrensIndexed:
      return Colors.Yellow;
    default:
      return Colors.Beige;
  }
};

export const getTransferInputMaxLength = (amount: string) => {
  return amount.startsWith('0') ? 1 : MAX_AMOUNT_LENGTH;
};

export const getAccountTitle = (account?: AccountSchema) => {
  if (account) {
    // If its a future account or a child account we want to display the name of the child
    if (account.product.type === ProductType.Future || getIsChildAccount(account)) {
      const accountOwnerName = getAccountOwner(account)?.fullName;
      if (accountOwnerName) {
        return accountOwnerName;
      }
    }
    // If the savings account has a name we want to display that
    if (account.name) {
      return account.name;
    }
    // Otherwise its the original savings account
    return GeneralStrings.SavingsAccount;
  }
  // If we don't have an account we are transfering to our external account and display that
  return NewAccountStrings.DisposableAccount;
};

export const getAudurAccountFromSelectedAccount = (account: SelectedAccount) => {
  if (!account || account.id === 'external') {
    return undefined;
  }
  return account;
};

export const prependZerosToString = (input: string, maxLength: number) => {
  if (input === '') {
    return input;
  }

  return input.padStart(maxLength, '0');
};

export const userParamInvalid = (user: string | null) => {
  if (user === 'individual' || user === 'company') {
    return false;
  }

  return true;
};

export const onboardParamInvalid = (onboard: string | null) => {
  if (onboard === 'true' || onboard === 'false') {
    return false;
  }

  return true;
};

export const canSkipRecurringAML = (date?: Date): boolean => {
  const userFirstSawRecurringAMLAt = getUserFirstSawRecurringAMLAt() ?? new Date();
  return differenceInDays(date ?? new Date(), userFirstSawRecurringAMLAt) <= DAYS_ALLOWED_TO_SKIP_AML;
};

export const answerToQuestionDisablesSurvey = (answer: SurveyAnswer, question: QuestionSchema) => {
  return answer.questionId === question.id && answerDisablesSurvey(answer);
};

export const answerToSurveyAnswer = (
  questionGroups: QuestionGroupSchema[]
): ((answer: AnswerReadSchema) => SurveyAnswer) => {
  return (answer: AnswerReadSchema): SurveyAnswer => {
    const questions = questionGroups.reduce((acc, nextGroup: QuestionGroupSchema) => {
      return acc.concat(nextGroup?.questions);
    }, [] as QuestionSchema[]);
    const questionOrUndefined = questions.find((question) => question.id === answer.questionId);
    return {
      ...answer,
      relevantPersons: answer.relevantPersons?.map((person): RelevantPersonsWriteSchema => {
        switch (person.kind) {
          case 'BENEFICIAL_OWNER':
            return {
              ...person,
              phone: undefined, // READ SCHEMA DOES NOT CONTAIN PHONE
            };
          case 'LEGAL_BINDER':
            return {
              ...person,
              phone: person.phone ?? '',
            };
          default:
            return person;
        }
      }),
      questionTagName: questionOrUndefined?.tagName,
      choiceCaption: questionOrUndefined?.choices?.find((choice) => choice.id === answer.choiceId)?.caption,
    };
  };
};

// eslint-disable-next-line
type HeadOfArray<T extends readonly unknown[]> = T extends readonly [infer U, ...infer _] ? U : T[0] | undefined;

// Type- & crash-safe way to get the first element of an array
export const headOfArray = <T extends readonly unknown[]>(val: T): HeadOfArray<T> => val[0] as HeadOfArray<T>;

export const isArrayEmpty = <T extends readonly unknown[]>(val: T): boolean => val === undefined || val.length === 0;
export const getIsFeatureEnabled = (featureFlag?: FeatureFlag) => {
  switch (featureFlag) {
    // Remove this case once the feature is enabled on production (https://github.com/kvika/audur-online-bank/milestone/3)
    case FeatureFlag.isIndexedSavingsAccountsEnabled:
      return process.env.NEXT_PUBLIC_INDEXED_SAVINGS_ACCOUNTS_FEATURE_ENBALED === 'true';
    default:
      return false;
  }
};
