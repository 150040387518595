export const CountryInfoIceland = {
  allowTax: true,
  id: 'IS',
  nameEN: 'Iceland',
  nameIS: 'Ísland',
};

export const CountryInfoOther = {
  allowTax: true,
  id: 'annad',
  nameEN: 'Other',
  nameIS: 'Annað',
};

export enum Languages {
  Icelandic = 'Íslenska',
  English = 'Enska',
}

export const DAYS_ALLOWED_TO_SKIP_AML = 10;

export const MAX_AMOUNT = 100000000000;

export const MAX_AMOUNT_LENGTH = 15;

export const BLOCKER_MSG = {
  onboarded: [
    'Nú getur fyrirtækið þitt byrjað að spara fyrir alvöru. Fyrst um sinn verður fyrirtækja-netbanki Auðar aðeins aðgengilegur í vafra.',
  ],
  confirmation: [
    'Áður en þú getur skráð þig inn í netbanka Auðar verða þeir ábyrgðaraðilar sem þú tilgreindir í umsókninni að sanna á sér deili.',
    'Einungis þeir sem hafa ekki átt í neinum viðskiptum við Kviku banka (þ.m.t. Auði) þurfa að sanna á sér deili.',
    'Viðkomandi fá sent SMS með hlekk þar sem þau auðkenna sig með rafrænum skilríkjum.',
    'Við látum þig vita þegar allir aðilar hafa auðkennt sig.',
  ],
};

export const BLOCKER_SUB_TITLE = {
  onboarded: [],
  confirmation: ['Það er örlítið eftir...'],
};

export const SIGNING_AGREEMENT_MSG = 'Vinsamlegast kíktu á símann þinn';

export const DOCUMENT_SIGNING_DELAY = 11000;

export const MAX_POLLING_TIME = 120000;

export enum AppStates {
  login = 'login',
  chooseAccount = 'chooseAccount',
  onboarding = 'onboarding',
  inApp = 'inApp',
}
export const EnterKey = 'Enter';
